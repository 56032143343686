import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent {
    logo = environment.settings.header.logo;
    title = environment.title;
    welcomeMessage = environment.settings.header.welcomeMessage;
}

<div class="w- mx-auto grid grid-cols-10 gap-4 bg-card p-4 shadow-md">
    <div class="col-span-5">
        <h2 class="font-semibold text-card-foreground">
            {{ setting?.name }}
        </h2>
        <p class="mr-5 text-sm text-card-foreground">{{ setting.description }}</p>
    </div>
    <div class="col-span-5">
        <div class="mb-4 justify-end text-card-foreground">
            @switch (setting.key) {
                @case ('THEME') {
                    <app-radio-button-selection
                        [options]="['dark', 'light']"
                        [selectedOption]="setting.value"
                        (selectionChange)="onThemeChange($event)" />
                }
                @case ('LANG') {
                    <app-control type="codelist" codelist="Language" [(ngModel)]="setting.value" />
                }
                @case ('DateFormat') {
                    <app-date-format-selector
                        [settingKey]="setting.key"
                        [settingValue]="setting.value"
                        (settingValueChanged)="updateSettingValue($event)" />
                }
                @case ('TimeFormat') {
                    <app-date-format-selector
                        [settingKey]="setting.key"
                        [timeFormat]="true"
                        [settingValue]="setting.value"
                        (settingValueChanged)="updateSettingValue($event)" />
                }
                @case ('AppBackgroundImage') {
                    @if (setting.value) {
                        <div class="flex items-center justify-between rounded-lg bg-white p-4 shadow-sm">
                            <div class="flex items-center space-x-2">
                                <span class="italic text-gray-600">
                                    {{ 'Current background image: ' | translate }}{{ setting.value }}
                                </span>
                                <app-button
                                    icon="faSolidTrash"
                                    variant="danger"
                                    tooltip="Delete image"
                                    size="sm"
                                    (click)="deleteImageSetting(setting)" />
                            </div>
                        </div>
                    }
                    <app-control
                        type="file"
                        [multi]="false"
                        [fileRestrictions]="{ allowedExtensions: ['jpg', 'png'] }"
                        [(ngModel)]="setting.imageModel" />
                }
                @case ('Logo') {
                    @if (setting.value) {
                        <div class="flex items-center justify-between rounded-lg bg-white p-4 shadow-sm">
                            <div class="flex items-center space-x-2">
                                <span class="italic text-gray-600">{{ 'Logo: ' | translate }}{{ setting.value }}</span>
                                <app-button
                                    icon="faSolidTrash"
                                    variant="danger"
                                    tooltip="Delete image"
                                    size="sm"
                                    (click)="deleteImageSetting(setting)" />
                            </div>
                        </div>
                    }
                    <app-control
                        type="file"
                        [multi]="false"
                        [fileRestrictions]="{ allowedExtensions: ['jpg', 'png'] }"
                        [(ngModel)]="setting.imageModel" />
                }
            }
        </div>
    </div>
</div>

<ngx-loading-bar height="4px" color="#fff" ref="router" [includeSpinner]="false"></ngx-loading-bar>
<ngx-loading-bar height="4px" color="#fff" ref="html" [includeSpinner]="false"></ngx-loading-bar>
<div kendoDialogContainer></div>
<div class="flex h-full overflow-y-auto overflow-x-clip bg-background" [ngClass]="{ 'p-2': isAuthenticated }">
    @if (isAuthenticated) {
        <app-sidebar></app-sidebar>
    }
    @if (environmentName) {
        <div
            class="title fixed bottom-[18px] right-16 z-10 select-none text-nowrap px-2 text-lg"
            kendoTooltip
            position="top"
            title="{{ gitVersionInfo.branch }} {{ gitVersionInfo.shortSHA }}"
            [attr.style]="environmentColor">
            {{ environmentName | translate }}
        </div>
    }
    <div class="h-full w-full overflow-x-auto border-none">
        <main class="h-full">
            <kendo-splitter class="h-full w-full border-none" #consoleSplitter orientation="vertical">
                <kendo-splitter-pane
                    class="h-full overflow-auto bg-background px-2 text-foreground"
                    [ngStyle]="{
                        'background-image': 'url(' + backgroundImageUrl + ')',
                        'background-size': 'cover'
                    }">
                    <router-outlet></router-outlet>
                </kendo-splitter-pane>
            </kendo-splitter>
        </main>
    </div>
</div>
